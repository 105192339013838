import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Logo from '../icons/logo'

import { default as color } from '../utils/color'
import Container from '../containers/container'

const Header = styled.header.attrs({
  className: `absolute w-100 pv4`
})`
`

const Nav = styled.nav.attrs({
  className: `flex justify-end`
})`
  @media (max-width: 600px) {
    display: none;
  }
`

const NavLink = styled(Link).attrs({
  className: `pl5 link`
})`
  color: ${color.white}
`

const NavLinkAnchor = styled.a.attrs({
  className: `pl5 link`
})`
  color: ${color.white}
`

const H3 = styled.h3.attrs({
  className: `f4 mt0 lh-title`
})`
  color: ${props => props.color ? props.color : color.dark};
  text-align: ${props => props.center ? 'center' : 'left'};
  margin-left: ${props => props.center ? 'auto' : '0'};
  margin-right: ${props => props.center ? 'auto' : '0'};
  margin-left: 10px;
  margin-top: 4px;
`

export default ({data}) => (
  <Header>
    <Container className="flex justify-between items-center">
      <div className="flex items-center justify-center">
        <Logo />
        <H3 color={color.white} className="mw5 mb1">Aubuchon, Buescher, Goodale, &amp; Bardot, LLC</H3>
      </div>
      <Nav>
          <NavLink to="/attorneys">Attorneys</NavLink>
          <NavLinkAnchor href="mailto:staff@abgblaw.com">Contact Us</NavLinkAnchor>
      </Nav>
    </Container>
  </Header>
)