import React from "react"

export function ButtonArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17">
      <path d="M 15.674 0.559 C 15.249 0.975 15.249 1.667 15.666 2.092 L 20.732 7.167 L 1.45 7.167 C 0.858 7.167 0.375 7.65 0.375 8.25 C 0.375 8.85 0.858 9.333 1.45 9.333 L 20.724 9.333 L 15.657 14.408 C 15.241 14.833 15.249 15.516 15.666 15.941 C 16.091 16.358 16.765 16.358 17.19 15.933 L 24.057 9.016 C 24.148 8.916 24.223 8.808 24.282 8.675 C 24.34 8.541 24.365 8.4 24.365 8.258 C 24.365 7.975 24.257 7.708 24.057 7.5 L 17.19 0.584 C 16.782 0.15 16.099 0.142 15.674 0.559 Z" fill="rgb(255,255,255)"></path>
    </svg>
  )
}

export function IOSEye() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path d="M 11.991 5.25 C 8.222 5.25 5.25 7.622 1.697 11.498 C 1.439 11.784 1.434 12.211 1.692 12.497 C 4.734 15.858 7.416 18.75 11.991 18.75 C 16.509 18.75 19.898 15.108 22.308 12.469 C 22.556 12.197 22.57 11.784 22.331 11.498 C 19.875 8.522 16.477 5.25 11.991 5.25 Z M 12.197 16.214 C 9.713 16.327 7.669 14.283 7.786 11.803 C 7.884 9.637 9.637 7.884 11.803 7.786 C 14.287 7.673 16.331 9.717 16.214 12.197 C 16.116 14.362 14.362 16.116 12.197 16.214 Z" fill="rgb(0,102,204)"></path>
        <path d="M 12 9.797 C 12 9.516 12.052 9.248 12.145 9.005 C 12.098 9.005 12.052 9 12 9 C 10.27 9 8.878 10.472 9.009 12.23 C 9.122 13.697 10.303 14.878 11.77 14.991 C 13.528 15.122 15 13.73 15 12 C 15 11.939 14.995 11.878 14.995 11.817 C 14.733 11.934 14.447 12 14.142 12 C 12.961 12 12 11.011 12 9.797 Z" fill="rgb(0,102,204)"></path>
      </svg>
    )
  }
  
  export function IOSHeart() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path d="M 16.5 2.625 L 16.453 2.625 C 14.592 2.625 12.947 3.609 12 5.063 C 11.053 3.609 9.408 2.625 7.547 2.625 L 7.5 2.625 C 4.598 2.653 2.25 5.011 2.25 7.922 C 2.25 9.656 3.009 12.117 4.491 14.142 C 7.313 18 12 21.375 12 21.375 C 12 21.375 16.688 18 19.509 14.142 C 20.991 12.117 21.75 9.656 21.75 7.922 C 21.75 5.011 19.402 2.653 16.5 2.625 Z" fill="rgb(119,0,255)"></path>
      </svg>
    )
  }
  
  export function IOSLock() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path d="M 17.625 9 L 16.5 9 L 16.5 6.811 C 16.5 4.341 14.531 2.287 12.061 2.25 C 9.558 2.217 7.5 4.256 7.5 6.75 L 7.5 9 L 6.375 9 C 5.344 9 4.5 9.844 4.5 10.875 L 4.5 19.875 C 4.5 20.906 5.344 21.75 6.375 21.75 L 17.625 21.75 C 18.656 21.75 19.5 20.906 19.5 19.875 L 19.5 10.875 C 19.5 9.844 18.656 9 17.625 9 Z M 12.656 14.85 L 12.656 18.075 C 12.656 18.427 12.384 18.731 12.033 18.75 C 11.658 18.769 11.344 18.469 11.344 18.094 L 11.344 14.85 C 10.805 14.588 10.448 14.016 10.505 13.364 C 10.57 12.638 11.166 12.056 11.892 12.005 C 12.769 11.944 13.5 12.638 13.5 13.5 C 13.5 14.095 13.158 14.606 12.656 14.85 Z M 15.188 9 L 8.813 9 L 8.813 6.75 C 8.813 5.902 9.145 5.105 9.75 4.5 C 10.355 3.895 11.152 3.562 12 3.562 C 12.848 3.562 13.645 3.895 14.25 4.5 C 14.855 5.105 15.188 5.902 15.188 6.75 Z" fill="rgb(244,190,13)"></path>
      </svg>
    )
  }
  
  export function IOSPerson() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path d="M 20.991 20.545 C 20.653 19.055 18.727 18.328 18.061 18.094 C 17.33 17.836 16.289 17.775 15.619 17.625 C 15.234 17.541 14.677 17.33 14.489 17.105 C 14.302 16.88 14.414 14.794 14.414 14.794 C 14.414 14.794 14.761 14.255 14.948 13.777 C 15.136 13.303 15.342 12 15.342 12 C 15.342 12 15.727 12 15.862 11.325 C 16.008 10.589 16.237 10.303 16.209 9.755 C 16.181 9.216 15.886 9.23 15.886 9.23 C 15.886 9.23 16.172 8.447 16.205 6.825 C 16.247 4.898 14.737 3 12.005 3 C 9.234 3 7.758 4.898 7.8 6.825 C 7.837 8.447 8.114 9.23 8.114 9.23 C 8.114 9.23 7.819 9.216 7.791 9.755 C 7.762 10.303 7.992 10.589 8.137 11.325 C 8.269 12 8.658 12 8.658 12 C 8.658 12 8.864 13.303 9.052 13.777 C 9.239 14.255 9.586 14.794 9.586 14.794 C 9.586 14.794 9.698 16.88 9.511 17.105 C 9.323 17.33 8.766 17.541 8.381 17.625 C 7.711 17.775 6.67 17.836 5.939 18.094 C 5.273 18.328 3.347 19.055 3.009 20.545 C 2.958 20.78 3.136 21 3.38 21 L 20.625 21 C 20.864 21 21.042 20.78 20.991 20.545 Z" fill="rgb(0,102,204)"></path>
      </svg>
    )
  }
  
  export function IOSMail() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path d="M 21.591 6.905 L 16.547 12.042 C 16.509 12.08 16.509 12.136 16.547 12.173 L 20.077 15.933 C 20.316 16.172 20.316 16.556 20.077 16.795 C 19.959 16.912 19.8 16.973 19.645 16.973 C 19.491 16.973 19.331 16.912 19.214 16.795 L 15.698 13.05 C 15.661 13.012 15.6 13.012 15.563 13.05 L 14.705 13.922 C 13.987 14.648 13.036 15.052 12.014 15.056 C 10.978 15.061 9.994 14.625 9.267 13.889 L 8.442 13.05 C 8.405 13.013 8.344 13.013 8.306 13.05 L 4.791 16.795 C 4.673 16.913 4.514 16.973 4.359 16.973 C 4.205 16.973 4.045 16.913 3.928 16.795 C 3.689 16.556 3.689 16.172 3.928 15.933 L 7.458 12.173 C 7.491 12.136 7.491 12.08 7.458 12.042 L 2.409 6.905 C 2.348 6.844 2.25 6.886 2.25 6.97 L 2.25 17.25 C 2.25 18.075 2.925 18.75 3.75 18.75 L 20.25 18.75 C 21.075 18.75 21.75 18.075 21.75 17.25 L 21.75 6.97 C 21.75 6.886 21.647 6.848 21.591 6.905 Z" fill="rgb(244,190,13)"></path>
        <path d="M 12 13.833 C 12.694 13.833 13.345 13.561 13.833 13.064 L 21.187 5.578 C 20.93 5.372 20.611 5.25 20.259 5.25 L 3.745 5.25 C 3.394 5.25 3.07 5.372 2.817 5.578 L 10.172 13.064 C 10.655 13.556 11.306 13.833 12 13.833 Z" fill="rgb(244,190,13)"></path>
      </svg>
    )
  }
  
  export function IOSToday() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path d="M 18.188 1.5 L 5.813 1.5 C 4.261 1.5 3 2.761 3 4.313 L 3 19.688 C 3 21.239 4.261 22.5 5.813 22.5 L 18.188 22.5 C 19.739 22.5 21 21.239 21 19.688 L 21 4.313 C 21 2.761 19.739 1.5 18.188 1.5 Z M 19.688 19.688 C 19.688 20.513 19.013 21.188 18.188 21.188 L 5.813 21.188 C 4.988 21.188 4.313 20.513 4.313 19.688 L 4.313 4.313 C 4.313 3.488 4.988 2.813 5.813 2.813 L 18.188 2.813 C 19.013 2.813 19.688 3.488 19.688 4.313 Z" fill="rgb(119,0,255)"></path>
        <path d="M 16.875 8.25 L 7.125 8.25 C 6.506 8.25 6 8.756 6 9.375 L 6 18.375 C 6 18.994 6.506 19.5 7.125 19.5 L 16.875 19.5 C 17.494 19.5 18 18.994 18 18.375 L 18 9.375 C 18 8.756 17.494 8.25 16.875 8.25 Z M 6.656 5.813 L 11.344 5.813 C 11.705 5.813 12 5.517 12 5.156 C 12 4.795 11.705 4.5 11.344 4.5 L 6.656 4.5 C 6.295 4.5 6 4.795 6 5.156 C 6 5.517 6.295 5.813 6.656 5.813 Z" fill="rgb(119,0,255)"></path>
      </svg>
    )
  }

export function StepActive() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="356" height="393">
      <g>
        <defs>
          <linearGradient id="iddKCSAiDlBg391601" gradientTransform="rotate(36, 0.5, 0.5)">
            <stop offset="0" stopColor="#00A8E8" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#0066CC" stopOpacity="1"></stop>
          </linearGradient>
        </defs>
        <path d="M 0 0 L 356 0 L 356 300.952 L 257.111 393 L 0 393 Z" fill="url(#iddKCSAiDlBg391601)"></path>
      </g>
      <g transform="translate(-607.466 -158.012)">
        <path d="M 150.973 214.803 C 150.973 215.332 150.973 516.374 150.973 516.374 C 67.593 516.374 0 448.865 0 365.588 C 0 282.312 67.593 214.803 150.973 214.803 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 276.518 390.455 L 276.518 516.374 L 150.443 516.374 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 150.443 0 C 150.443 0.529 150.443 301.571 150.443 301.571 C 233.823 301.571 301.415 234.062 301.415 150.785 C 301.415 67.509 233.823 0 150.443 0 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 150.973 731.177 C 150.973 731.706 150.973 1032.747 150.973 1032.747 C 67.593 1032.747 0 965.238 0 881.962 C 0 798.685 67.593 731.177 150.973 731.177 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 150.443 516.374 C 150.443 516.903 150.443 817.944 150.443 817.944 C 233.823 817.944 301.415 750.435 301.415 667.159 C 301.415 583.882 233.823 516.374 150.443 516.374 Z" fill="rgba(232, 244, 252, 0.08)"></path>
      </g>
      <g transform="translate(-909.411 59.965)">
        <path d="M 150.443 214.803 C 150.443 215.332 150.443 516.374 150.443 516.374 C 233.823 516.374 301.415 448.865 301.415 365.588 C 301.415 282.312 233.823 214.803 150.443 214.803 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 24.897 390.455 L 24.897 516.374 L 150.973 516.374 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 150.973 -0 C 150.973 0.529 150.973 301.571 150.973 301.571 C 67.593 301.571 0 234.062 0 150.785 C 0 67.509 67.593 -0 150.973 -0 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 150.443 731.177 C 150.443 731.706 150.443 1032.747 150.443 1032.747 C 233.823 1032.747 301.415 965.238 301.415 881.962 C 301.415 798.685 233.823 731.177 150.443 731.177 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 150.973 516.374 C 150.973 516.903 150.973 817.944 150.973 817.944 C 67.593 817.944 0 750.435 0 667.159 C 0 583.882 67.593 516.374 150.973 516.374 Z" fill="rgba(232, 244, 252, 0.08)"></path>
      </g>
      <g transform="translate(-71.192 19.058)">
        <path d="M 129.816 0 C 153.221 0 172.194 18.95 172.194 42.326 C 172.194 65.702 153.221 84.651 129.816 84.651 C 106.411 84.651 87.438 65.702 87.438 42.326 C 87.438 18.95 106.411 0 129.816 0 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 177.194 350.303 L 177.194 224.384 L 51.119 224.384 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 0 126.075 L 0 0 L 125.919 0 Z" transform="translate(177.272 350.225) rotate(90 62.959 63.038)" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 223.81 493.153 C 232.587 493.153 239.702 500.259 239.702 509.025 C 239.702 517.791 232.587 524.897 223.81 524.897 C 215.033 524.897 207.918 517.791 207.918 509.025 C 207.918 500.259 215.033 493.153 223.81 493.153 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 88.884 0 C 88.884 0.31 88.884 176.929 88.884 176.929 C 39.795 176.929 0 137.322 0 88.465 C 0 39.607 39.795 0 88.884 0 Z" transform="translate(44.023 306.545) rotate(-90 44.442 88.465)" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 397.561 524.897 C 495.862 524.897 575.55 604.486 575.55 702.665 C 575.55 800.843 495.862 880.433 397.561 880.433 C 299.261 880.433 219.572 800.843 219.572 702.665 C 219.572 604.486 299.261 524.897 397.561 524.897 Z" fill="rgba(232, 244, 252, 0.08)"></path>
      </g>
      <g transform="translate(170.44 -268.059)">
        <path d="M 134.816 0 C 158.221 0 177.194 18.95 177.194 42.326 C 177.194 65.702 158.221 84.651 134.816 84.651 C 111.411 84.651 92.438 65.702 92.438 42.326 C 92.438 18.95 111.411 0 134.816 0 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 177.194 351.303 L 177.194 225.384 L 51.119 225.384 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 0 126.075 L 0 0 L 125.919 0 Z" transform="translate(177.272 351.225) rotate(90 62.959 63.038)" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 223.81 494.153 C 232.587 494.153 239.702 501.259 239.702 510.025 C 239.702 518.791 232.587 525.897 223.81 525.897 C 215.033 525.897 207.918 518.791 207.918 510.025 C 207.918 501.259 215.033 494.153 223.81 494.153 Z" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 88.884 0 C 88.884 0.31 88.884 176.929 88.884 176.929 C 39.795 176.929 0 137.322 0 88.465 C -0 39.607 39.795 0 88.884 0 Z" transform="translate(44.023 307.545) rotate(-90 44.442 88.465)" fill="rgba(232, 244, 252, 0.08)"></path>
        <path d="M 397.561 525.897 C 495.862 525.897 575.55 605.486 575.55 703.665 C 575.55 801.843 495.862 881.433 397.561 881.433 C 299.261 881.433 219.572 801.843 219.572 703.665 C 219.572 605.486 299.261 525.897 397.561 525.897 Z" fill="rgba(232, 244, 252, 0.08)"></path>
      </g>
    </svg>
  )
}
  