import React from 'react'
import styled from 'styled-components'

import { default as color } from '../utils/color'
import { StepActive } from '../utils/icon'

const Step = styled.div.attrs({
    className: `pv4 ph4 mb4 mb0-ns`
})`
    position: relative;

    > * {
        position: relative;
        z-index: 1;
    }
`

const StepBackground = styled.div.attrs({
    className: ``
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0.05;

    svg {
        width: 100%;
        height: 100%;
    }
`

const Number = styled.span.attrs({
    className: `db f1 pb4 b`
  })`
    color: ${props => props.color ? props.color : color.blue};
    text-align: ${props => props.center ? 'center' : 'left'};
`

const H3 = styled.h3.attrs({
    className: `f3 mt0 mb3 lh-solid`
  })`
    color: ${props => props.color ? props.color : color.dark};
    text-align: ${props => props.center ? 'center' : 'left'};
`

const P = styled.p.attrs({
    className: `f5 lh-copy pa0 ma0 h4`
  })`
    color: ${color.dark};
`

const Divider = styled.div.attrs({
    className: `bt mv4`
})`
    border-color: #C7E3FA;
`

const ContactLink = styled.a.attrs({
    className: `mt2 link bb pb1`
})`
    display: inline-block;
    color: ${color.blue};
`


export default ({ number, title, description, linkText, url }) => (
    <Step>
        <StepBackground><StepActive /></StepBackground>
        <Number>{number}</Number>
        <H3>{title}</H3>

        <Divider />

        <P>{description}</P>
        <ContactLink href={url}>{linkText}</ContactLink>
    </Step>
)