import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="66"
      fill="none"
      viewBox="0 0 100 66"
    >
      <path
        fill="#06C"
        d="M95.015 0H4.616C3.138 0 2.323 1.718 3.26 2.863c.333.408.831.644 1.357.644h90.4c.464 0 .91-.185 1.24-.514C97.36 1.89 96.577 0 95.015 0z"
      ></path>
      <circle cx="4.704" cy="7.264" r="2.561" fill="#06C"></circle>
      <circle cx="15.948" cy="7.264" r="2.561" fill="#06C"></circle>
      <path
        fill="#06C"
        d="M16.003 4.704H4.787l1.336 1.169h8.711l1.17-1.17zM15.502 14.036v39.763a2.199 2.199 0 11-4.397 0V7.375c0-.292.237-.529.529-.529.292 0 .532.237.564.527.072.653.293 1.768.966 2.48 1.122 1.184 2.338 2.551 2.338 4.183zM5.149 14.036v39.763a2.199 2.199 0 104.397 0V7.375a.529.529 0 00-.528-.529.582.582 0 00-.564.527c-.073.653-.293 1.768-.967 2.48-1.122 1.184-2.338 2.551-2.338 4.183zM15.095 57.39H4.856a1.475 1.475 0 100 2.95h10.239a1.475 1.475 0 000-2.95z"
      ></path>
      <circle cx="83.858" cy="7.264" r="2.561" fill="#06C"></circle>
      <circle cx="95.102" cy="7.264" r="2.561" fill="#06C"></circle>
      <path
        fill="#06C"
        d="M95.157 4.704H83.941l1.336 1.169h8.711l1.17-1.17zM94.656 14.036v39.763a2.199 2.199 0 11-4.397 0V7.375c0-.292.237-.529.529-.529.292 0 .532.237.564.527.072.653.292 1.768.966 2.48 1.122 1.184 2.338 2.551 2.338 4.183zM84.303 14.036v39.763a2.199 2.199 0 104.397 0V7.375a.529.529 0 00-.529-.529.582.582 0 00-.564.527c-.072.653-.292 1.768-.966 2.48-1.122 1.184-2.338 2.551-2.338 4.183zM94.249 57.39H84.01a1.475 1.475 0 100 2.95H94.25a1.475 1.475 0 000-2.95zM46.503 9.045h31.632c.451 0 1.325-1.705 1.325-2.157 0-.45-.874-2.157-1.325-2.157H46.503a2 2 0 00-2 2v.314a2 2 0 002 2z"
      ></path>
      <path
        fill="#06C"
        d="M53.302 9.045H21.67c-.451 0-1.325-1.705-1.325-2.157 0-.45.874-2.157 1.325-2.157h31.632a2 2 0 012 2v.314a2 2 0 01-2 2zM96.504 61.648H2.963a2.06 2.06 0 100 4.119h93.541c1.57 0 2.562-1.685 1.802-3.058a2.06 2.06 0 00-1.802-1.062zM18.814 10.41l-2.003 2.003v43.919l2.003 3.479H47.62V10.409H18.814zM80.72 10.381l2.303 2.005V56.33l-2.303 3.48h-33.1V10.382h33.1z"
      ></path>
      <path
        fill="#fff"
        d="M33.153 18.57c0-2.775-1.505-4.186-7.573-4.186-6.067 0-7.62 1.41-7.62 4.186v35.841h5.221V37.15h4.751v17.262h5.221V18.57zm-5.22 13.452H23.18V20.64c0-.847.424-1.317 2.4-1.317 2.022 0 2.351.47 2.351 1.317v11.382zM46.739 34.045c1.881-.612 2.493-1.74 2.493-3.434V19.134c0-2.68-1.6-4.28-7.432-4.28h-7.62v39.557h7.855c5.88 0 7.479-1.505 7.479-4.28V37.714c0-1.74-.659-3.01-2.775-3.67zm-7.29-14.44h2.727c1.505 0 1.835.611 1.835 1.41v9.69c0 .847-.377 1.411-2.352 1.411h-2.21V19.605zm4.844 28.692c0 .8-.33 1.41-1.835 1.41h-3.057V36.774h2.54c1.928.047 2.352.564 2.352 1.411v10.113zM65.493 25.908V18.57c0-2.728-1.505-4.186-7.573-4.186-6.068 0-7.62 1.458-7.62 4.233v32.079c0 2.775 1.552 4.233 7.62 4.233s7.573-1.458 7.573-4.187V32.305H57.92v4.704h2.305v11.665c0 .894-.33 1.411-2.305 1.411-2.023 0-2.399-.517-2.399-1.411v-27.94c0-.846.377-1.41 2.399-1.41 1.975 0 2.305.564 2.305 1.41v5.175h5.268zM78.942 34.045c1.881-.612 2.493-1.74 2.493-3.434V19.134c0-2.68-1.6-4.28-7.432-4.28h-7.62v39.557h7.855c5.88 0 7.479-1.505 7.479-4.28V37.714c0-1.74-.659-3.01-2.775-3.67zm-7.29-14.44h2.727c1.505 0 1.835.611 1.835 1.41v9.69c0 .847-.377 1.411-2.352 1.411h-2.21V19.605zm4.844 28.692c0 .8-.33 1.41-1.834 1.41h-3.058V36.774h2.54c1.929.047 2.352.564 2.352 1.411v10.113z"
      ></path>
    </svg>
  );
}

export default Logo;
