import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from "gatsby"

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Section from '../containers/section'
import Container from '../containers/container'
import Button from '../components/button'
import Card from '../components/card'
import Step from '../components/step'

import { default as color } from '../utils/color'
import heroImage from '../images/hero-image-landing-page.jpg'

const SuperText = styled.p.attrs({
  className: `f6 ttu tracked ma0 mb2`
})`
  color: ${props => props.color ? props.color : color.dark};
  text-align: ${props => props.center ? 'center' : 'left'};

  @media (max-width: 600px) {
    text-align: center;
  }
`

const H1 = styled.h1.attrs({
  className: `f1 mw6-ns mt0 mb4 pr6-ns lh-solid`
})`
  color: ${props => props.color ? props.color : color.dark};
  text-align: ${props => props.center ? 'center' : 'left'};

  @media (max-width: 600px) {
    text-align: center;
  }
`
const H2 = styled.h2.attrs({
  className: `f1 mt0 mb3 lh-solid`
})`
  color: ${props => props.color ? props.color : color.dark};
  text-align: ${props => props.center ? 'center' : 'left'};
  margin-left: ${props => props.center ? 'auto' : '0'};
  margin-right: ${props => props.center ? 'auto' : '0'};

  @media (max-width: 600px) {
    text-align: center;
  }
`

const H3 = styled.h3.attrs({
  className: `f4 mt0 lh-title`
})`
  color: ${props => props.color ? props.color : color.dark};
  text-align: ${props => props.center ? 'center' : 'left'};
  margin-left: ${props => props.center ? 'auto' : '0'};
  margin-right: ${props => props.center ? 'auto' : '0'};

  @media (max-width: 600px) {
    text-align: center;
  }
`

const P = styled.p.attrs({
  className: `f4 measure lh-copy`
})`
  color: ${props => props.color ? props.color : color.dark};
  text-align: ${props => props.center ? 'center' : 'left'};
  margin: ${props => props.center ? '0 auto' : '0'};
  
  a {
    color: ${props => props.color ? props.color : color.dark};
    padding-bottom: 3px;
    border-bottom: 1px solid ${props => props.color ? props.color : color.dark};
  }
`

const GridContainer = styled.div.attrs({
  className: `mt4`
})`
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
  }
`

const Quote = styled.div.attrs({
  className: `f2 tc i white b` 
})``

const Citation = styled.div.attrs({
  className: `f4 tc i white mt4` 
})``

const Nav = styled.nav.attrs({
  className: `flex justify-around justify-end-ns`
})``

const NavLink = styled(Link).attrs({
  className: `pl5-ns link`
})`
  color: ${color.white}
`

const NavLinkAnchor = styled.a.attrs({
  className: `pl5 link`
})`
  color: ${color.white}
`

const Index = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <Header />

    <Section backgroundImage={heroImage} verticalSpace>
      <Container>
        <SuperText color={color.white}>practicing law in Franklin County since the 1960s</SuperText>
        <H1 color={color.white}>Local, Experienced, Dedicated Legal Representatives</H1>

        <div className="mt5 mt0-ns">
          <div className="fl-ns w-25-ns">
            <Button href="mailto:staff@abglawfirm.com">Contact Us</Button>

            <P className="pt3 pb4 pb0-ns" color={color.white}>or <a href="tel:6365834541" className="link">Call 636-583-4541</a></P>
          </div>

          <div className="fl-ns pl4-ns">
            <P color={color.white}>Get honest legal advice for difficult situations at <strong>Aubuchon, Buescher, Goodale, &amp; Bardot, LLC.</strong> 
            &nbsp;Adoption, business, civil, criminal, estate planning &amp; probate, family, juvenile, real estate and trust administration law.</P>
          </div>
        </div>
      </Container>
    </Section>

    <Section>
      <Container>
        <H2 center>How can we help?</H2>
        <P center className="measure-wide">We represent clients in a variety of specialties. 
        Our goal is to minimize your stress and protect your rights as we help you navigate the legal system.</P>
        
        <GridContainer>
          <Card 
            title="Civil Law"  
            image={data.cardBg.childImageSharp.fluid} 
          >
            <ul className="mv0">
              <li>Business Disputes</li>  
              <li>Contractor Disputes</li>  
              <li>General Civil Litigation</li>  
              <li>Personal Injury</li>  
            </ul>
          </Card>

          <Card 
            title="Divorce &amp; Family Law"  
            image={data.cardBg.childImageSharp.fluid} 
            >
            <ul className="mv0">
              <li>Child support</li>
              <li>Custody</li>
              <li>Divorce</li>
              <li>Division of Assets and Debts</li>
              <li>Maintenance </li>
              <li>Modifications </li>
              <li>Juvenile Law</li>
              <li>Paternity </li>
            </ul>
          </Card>

          <Card 
            title="Criminal Law"  
            image={data.cardBg.childImageSharp.fluid} 
            >
            <ul className="mv0">
              <li>DWI</li>
              <li>Felonies</li>
              <li>Misdemeanors</li>
              <li>Traffic  </li>
            </ul>
          </Card>
        </GridContainer>

        <P center className="measure-wide pt5">Of course, seeing us doesn't always have to be about trouble. 
        We're also here to help for life's next big step...</P>

        <GridContainer>
          <Card 
            title="Adoption" 
            image={data.cardBg.childImageSharp.fluid} 
            >
            <ul className="mv0">
              <li>Agency</li>
              <li>Children’s Division </li>
              <li>Foreign Recognition </li>
              <li>Interstate (ICPC) </li>
              <li>Newborn</li>
              <li>Private</li>
              <li>Stepparent  </li>
            </ul>
          </Card>

          <Card 
            title="Real Estate"  
            image={data.cardBg.childImageSharp.fluid} 
            >
            <ul className="mv0">
              <li>Contracts</li>
              <li>Deeds</li>
              <li>Easements</li>
              <li>Leases</li>
              <li>Boundary Disputes</li>
              <li>Condemnation </li>
              <li>Landlord Tenant</li>
              <li>Quiet Title Actions  </li>
            </ul>
          </Card>
            
          <Card 
            title="Trusts &amp; Estate" 
            image={data.cardBg.childImageSharp.fluid} 
            >
            <ul className="mv0">
              <li>Adult &amp; Minor Guardianship</li>
              <li>Contested Estates</li>
              <li>Power of Attorney </li>
              <li>Probate</li>
              <li>Trust Administration </li>
              <li>Trusts</li>
              <li>Wills</li>
            </ul>
          </Card>
        </GridContainer>
      </Container>
    </Section>

    <Section backgroundColor={color.light}>
      <Container>
        <H2>How does this work?</H2>

        <GridContainer className="pt4">
          <Step 
            number="01"
            title="Contact Our Team" 
            description="Fill out the details of your case without being too specific about the persons involved. Privacy first!" 
            linkText="Contact Now"
            url="mailto:staff@abglawfirm.com"
            />
          
          <Step 
            number="02"
            title="Setup a consultation" 
            description="If we think we can help you, we’ll be in touch to setup an appointment to talk to one of our attorneys. Our first consultation costs you nothing." 
            linkText="Schedule an Appointment"
            url="mailto:staff@abglawfirm.com"
            />
          
          <Step 
            number="03"
            title="Rest easy..." 
            description="We don’t bill you until we’ve both agreed to work together. We strive to be transparent about our fees. Any questions you have, feel free to ask!" 
            linkText="Ask A Question"
            url="mailto:staff@abglawfirm.com"
            />
        </GridContainer>
      </Container>
    </Section>

    <Section backgroundColor={color.dark}>
      <Container>
        <Quote color={color.white}>&ldquo;When you want to help people, you tell them the truth. When you want to help yourself, you tell them what they want to hear.&rdquo;</Quote>
        <Citation>- Thomas Sowell</Citation>
      </Container>

      <div className="bt b--white-30 mv5"></div>

      <Container>
        <div class="flex-ns items-center justify-between">
          <div className="mb4 mb0-ns">
            <H3 color={color.white} center className="mw5 mb1">Aubuchon, Buescher, Goodale, &amp; Bardot, LLC</H3>
            <P center color={color.white50}>&copy; 1995 - 2019</P>
          </div>

          <Nav>
            <NavLink to="/attorneys">Attorneys</NavLink>
            <NavLinkAnchor href="mailto:staff@abglawfirm.com">Contact Us</NavLinkAnchor>
          </Nav>
        </div>
      </Container>
    </Section>
  </Layout>
)

export default Index

export const query = graphql`
  query {
    cardBg: file(
      relativePath: { regex: "/card-bg/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 306
          quality: 80
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
