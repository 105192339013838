import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"

const Card = styled.div.attrs({
    className: `db br3 overflow-hidden link mb4 mb0-ns relative`
})`
    box-shadow: 0px 2px 30px -10px rgba(2, 102, 202, 0.5);

    ul {
        padding-left: 20px;
    }

    li {
        padding-top: 6px;
    }
`

const H3 = styled.h3.attrs({
    className: `f4 mt0 mb3 lh-solid`
  })`
    position: absolute;
    color: white;
    text-align: ${props => props.center ? 'center' : 'left'};
    top: 24px;
    z-index: 100;
`

const Image = styled(Img)`
  height: 60px;
  position: relative;
  z-index: 1;
`


export default ({ title, description, image, children }) => (
    <Card>
        {image && (
        <Image
            fluid={image}
            title={title}
        />
        )}
        <div className="pa3">
            <H3>{title}</H3>
            {children}
        </div>
    </Card>
)