import React from 'react'
import styled from 'styled-components'

import { default as color } from '../utils/color'
import { ButtonArrow } from '../utils/icon'

const Button = styled.a.attrs({
    className: `db br3 link f5 pv3 ph3 flex align-center v-mid`
})`
    position: relative;
    background-color: ${color.blue};
    color: ${color.white};
    box-shadow: 0px 2px 30px 0px ${color.blue};
    width: 100%;

    &:hover {
        
    }

    span {
        display: block;
        position: absolute;
        right: 0;
    }
`

export default (props) => (
    <Button {...props}>
        {props.children} <span className="pr3"><ButtonArrow /></span>
    </Button>
)